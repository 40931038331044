.slider_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.slider-value_container {
  position: absolute;
  display: flex;
  width: 200px;
  justify-content: space-between;
}

.slider-min_value,
.slider-max_value {
  font-size: 17px;
  color: var(--agt-primary-color-1);
}

.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value,
.slider__left-min_value,
.slider__right-max_value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
  margin-top: 23px;
}

.slider__track {
  background-color: var(--agt-white-color);
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: var(--agt-primary-color-1);
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: var(--agt-primary-color-1);
  font-size: 17px;
}

.slider__left-min_value,
.slider__right-max_value {
  color: var(--agt-primary-text-color);
  font-size: 13px;
  margin-top: 40px;
}

.slider__left-value,
.slider__left-min_value {
  left: 0px;
}

.slider__right-value,
.slider__right-max_value {
  right: 0px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--left,
.thumb--right {
  margin-top: 45px;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: var(--agt-primary-color-1);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px var(--agt-primary-color-1);
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: var(--agt-primary-color-1);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px var(--agt-primary-color-1);
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
