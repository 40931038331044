@import url('assets/css/font.css');
@import url('assets/css/react-datepicker.css');

:root {
  --agt-primary-color-1: #00529a;
  --agt-primary-color-2: #23d7c5;
  --agt-light-green-color: #a6f8f0;
  --agt-secondary-color-1: #a0a8bc;
  --agt-secondary-color-2: #7d828e;
  --agt-background-color-1: #eff1f5;
  --agt-background-color-2: #e6e8ec;
  --agt-background-color-3: #ccdceb;
  --agt-background-color-4: #f0f5fc;
  --agt-primary-text-color: #393d43;
  --agt-secondary-text-color: #acb1bf;
  --agt-error-color: #f45a49;
  --agt-success-color: #9bcc55;
  --agt-warning-color: #f5a71d;
  --agt-disable-color-1: #d8d9e0;
  --agt-disable-color-2: #b5b6bf;
  --agt-white-color: #ffffff;
  --agt-tqm-pantone-1: #00529a;
  --agt-tqm-pantone-2: #00aeef;
  --agt-tqm-pantone-1-20: #00529a20;
  --agt-light-sky-color: #cae6ff;
  --agt-dark-sky-color: #0d75d1;
  --agt-sky-color: #00aeef;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  border: 4px solid var(--agt-secondary-text-color);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  font-family: NotoSansThai, NotoSans, sans-serif;
}

#root {
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0;
  color: var(--agt-primary-text-color);
  overflow: hidden auto !important;
}

h1 {
  font-size: 48px;
  line-height: 73px;
}
h2 {
  font-size: 38px;
  line-height: 57px;
}
h3 {
  font-size: 28px;
  line-height: 42px;
}
h4 {
  font-size: 24px;
  line-height: 36px;
}

small,
.small,
.agt-small {
  font-size: 13px;
  line-height: 20px;
}

a {
  color: var(--agt-primary-color-1);
  cursor: pointer;
}
a:hover {
  color: var(--agt-sky-color);
}
a.disabled {
  color: var(--agt-disable-color-1);

  pointer-events: none;
  cursor: not-allowed;
}
a.ghost {
  text-decoration: none;
}
a.ghost:hover {
  color: var(--agt-sky-color);
}
a.secondary {
  color: var(--agt-secondary-text-color);
}
a.secondary:hover {
  color: var(--agt-primary-text-color);
}

button {
  width: 160px;
  height: 48px;
  padding: 0 20px;

  color: white;
  font-size: 17px;
  line-height: 26px;
  background-color: var(--agt-tqm-pantone-1);
  border: none;
  border-radius: 5px;
  outline: none;

  cursor: pointer;
}
button.agt-small {
  font-size: 13px;
  line-height: 20px;
}
button:hover {
  background-color: var(--agt-dark-sky-color);
}
button:disabled {
  color: var(--agt-disable-color-2);
  background-color: var(--agt-disable-color-1);
  cursor: not-allowed;
}
button.secondary {
  color: var(--agt-primary-color-1);
  background-color: white;
  border: 1px solid var(--agt-primary-color-1);
}
button.secondary:hover {
  color: var(--agt-dark-sky-color);
  border-color: var(--agt-dark-sky-color);
}
button.secondary:disabled {
  color: var(--agt-disable-color-1);
  border-color: var(--agt-disable-color-1);
  cursor: not-allowed;
}
button.tab {
  color: var(--agt-secondary-text-color);
  background-color: var(--agt-white-color);
  border: none;
  border-radius: 8px;
}
button.tab:hover,
button.tab.active {
  font-weight: bold;
  color: var(--agt-primary-color-1);
  background-color: var(--agt-background-color-3);
}

.error {
  color: var(--agt-error-color) !important;
}
.success {
  color: var(--agt-success-color) !important;
}
.ghost:not(a) {
  color: var(--agt-secondary-text-color) !important;
}
.ghost:hover:not(a) {
  color: var(--agt-primary-text-color) !important;
}

input:not([type='checkbox']) {
  padding: 0 16px;
  height: 48px;
  color: var(--agt-primary-text-color);
  font-size: 17px;
  line-height: 26px;
  border: 1px solid var(--agt-secondary-color-1);
  border-radius: 4px;
  background-color: white;
}
input[type='range'] {
  padding: unset;
  height: unset;
  color: unset;
  font-size: unset;
  line-height: unset;
  border: unset;
  border-radius: unset;
  background-color: unset;
}
input[id^='react-select'] {
  height: unset;
}
input::placeholder {
  color: var(--agt-secondary-text-color);
}
input:disabled {
  background-color: var(--agt-background-color-1);
  cursor: not-allowed;
}
textarea {
  padding: 16px;
  color: var(--agt-primary-text-color);
  font-size: 17px;
  line-height: 26px;
  border: 1px solid var(--agt-secondary-color-1);
  border-radius: 4px;
  background-color: white;
}
textarea::placeholder {
  color: var(--agt-secondary-text-color);
}
textarea:disabled {
  background-color: var(--agt-background-color-1);
  cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 34px;
    line-height: 51px;
  }
  h2 {
    font-size: 28px;
    line-height: 42px;
  }
  h3 {
    font-size: 23px;
    line-height: 34px;
  }
  h4 {
    font-size: 19px;
    line-height: 28px;
  }

  small {
    font-size: 12px;
    line-height: 18px;
  }
}
